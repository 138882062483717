import React, {Component} from 'react'; 
import Dropzone from './Dropzone';
import Progress from './Progress';
import UserPrompt from './UserPrompt';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class UploadFile extends Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const { cookies } = props;

		this.state = {
			files: [],
			externalEmail: [],
			email: [],
			uploading: false,
			uploadProgress: {},
			filesProcessed: [],
			successfullyUploaded: false,
			successfullyDownloaded: false,
			uploadState: '',
			user: cookies.get('user') || '',
			password: cookies.get('password') || '',
			loginError: false,
			showLogin: true
		};

		this.onFilesAdded = this.onFilesAdded.bind(this);
		this.uploadFiles = this.uploadFiles.bind(this);
		this.sendRequest = this.sendRequest.bind(this);
		this.renderActions = this.renderActions.bind(this);
		this.renderRemoveButton = this.renderRemoveButton.bind(this);
		this.handleUser = this.handleUser.bind(this);
		this.handleAuth = this.handleAuth.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.removeFile = this.removeFile.bind(this);
	}

	componentDidMount(){
		this.handleAuth();
	};


	onFilesAdded(files) {
		
		//Check for duplicate files
		var fileArrayCpy = [...this.state.files]; 
		var inputArrayCpy = [...files]; 

		files.forEach(file => {
			var index = fileArrayCpy.map(function(o) { return o.name; }).indexOf(file.name);
			var index2 = inputArrayCpy.map(function(o) { return o.name; }).indexOf(file.name);
			if (index !== -1) {
					inputArrayCpy.splice(index2, 1);
			}
		})
		files = inputArrayCpy;

		//Add new files
		this.setState(prevState => ({
			files: prevState.files.concat(files)
		}));

		//Add state of new files
		files.forEach(file => {
			this.setState(prevState => ({
				filesProcessed: prevState.filesProcessed.concat({name:file.name, status : false})
			}));
		});
	}


	handleUser(user,password) {
		const { cookies } = this.props;
 
		cookies.set('user', user, { path: '/' });
		this.setState({ user });

		cookies.set('password', password, { path: '/' });
		this.setState({ password });
	}

	handleSubmit(e){
		e.preventDefault();
		this.handleAuth();
	}

	handleAuth() {
		fetch('/auth', { 
			method: 'post', 
			headers: new Headers({
				'Authorization': 'Basic '+ btoa(this.state.user + ':' + this.state.password), 
				'Content-Type': 'application/x-www-form-urlencoded'
			}), 
			body: 'A=1&B=2'
		}).then((res) => {
			if(res.status == 200){
				this.setState({ loginError: false }); 
				this.setState({ showLogin: false }); 
				this.handleUser(this.state.user,this.state.password);
			}else{
				this.setState({ showLogin: true });
				this.setState({ loginError: true }); 
			}
		}).catch((error) => {
			console.log('Auth error', error);
		});
	}


	removeFile(file) {
		var arrayCpy = [...this.state.files]; 
		var index = arrayCpy.indexOf(file)
		if (index !== -1) {
			arrayCpy.splice(index, 1);
			this.setState({files: arrayCpy});
		}

		var progressCpy = [...this.state.filesProcessed]; 
		var index2 = progressCpy.map(function(o) { return o.name; }).indexOf(file.name);
		if (index2 !== -1) {
			progressCpy.splice(index2, 1);
			this.setState({filesProcessed: progressCpy});
		}
	}


	async uploadFiles() {
		this.setState({ uploadProgress: {}, uploading: true ,uploadState: 'upload' });
		const promises = [];
		
			promises.push(this.sendRequest(this.state.files));

		try {
			await Promise.all(promises);

			this.setState({ successfullyUploaded: true, uploading: false ,uploadState : 'upload-success'});
		} catch (e) {
			console.log(e);
			this.setState({ successfullyUploaded: true, uploading: false ,uploadState : 'upload-fail'});
		}
	}


	sendRequest(files) {
		return new Promise((resolve, reject) => {
			const req = new XMLHttpRequest();
			const formData = new FormData();

			req.upload.addEventListener("progress", event => {
				if (event.lengthComputable) {
					const copy = { ...this.state.uploadProgress };
					copy[files] = {
						state: "pending",
						percentage: (event.loaded / event.total) * 100
					};
					this.setState({ uploadProgress: copy });
				}
			});

			req.upload.addEventListener("load", event => {
				const copy = { ...this.state.uploadProgress };
				copy[files] = { state: "done", percentage: 100 };
				this.setState({ uploadProgress: copy });
				resolve(req.response);
			});

			req.upload.addEventListener("error", event => {
				const copy = { ...this.state.uploadProgress };
				copy[files] = { state: "error", percentage: 0 };
				this.setState({ uploadProgress: copy });
				reject(req.response);
			});

		files.forEach(file => {
			formData.append("file", file, file.name);
		})
		
			var object = this;
			req.open("POST", "/app/upload");

			req.setRequestHeader('Authorization', 'Basic '+ btoa(this.state.user + ':' + this.state.password))
		 
			req.onreadystatechange = function() {
				
					if (this.readyState == 4 && this.status == 200) {
						if(this.responseText == "success"){
							object.setState({ uploadState: 'download-success' });
							window.open('/download');
						}else{
							console.log('Download failed');
							object.setState({ uploadState: 'download-fail' });
						}          
					}
			};

			req.send(formData);
		});
	}


	renderProgress(file) { 
		const uploadProgress = this.state.uploadProgress[file.name];
		if (this.state.uploading || this.state.successfullyUploaded) {
			return (
				<div className="ProgressWrapper">
					<Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
				 { <svg className="CheckIcon"
						alt="done"
						style={{
							opacity:
								uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
						}} xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
					<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
					</svg>}
				</div>
			);
		}
	}


	renderRemoveButton(file) {
		if (this.state.uploading || this.state.successfullyUploaded) {
			return (
				 <div className="RemoveFile" 
					filename={file.name} 
				>
				</div>
			);
		} else {
			return (
				<div className="RemoveFile" 
					onClick={ () => { this.removeFile(file) } } 
					filename={file.name} 
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
						<path fill="#F3C178" stroke="#F3C178" d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
					</svg>

				</div>
			);
		}
	}

	renderMessage() {
		
		if (this.state.uploadState == 'upload-success') {
			return (
				<p style= { { backgroundColor: 'rgba(0,168,120,.5)' } } >
					Filer uppladdade. Processar...
				</p>
			);
		} else if (this.state.uploadState == 'download-success') {
			return (
				<p style= { { backgroundColor: 'rgba(0,168,120,.5)' } } >
					Filer nedladdade
				</p>
			);
		}else if(this.state.uploadState == 'download-fail') {
			return (
				<p style= { { backgroundColor: 'rgba(255,0,0,.5)' } }>
					Nedladdning misslyckades
				</p>
			);
		}else if(this.state.uploadState == 'upload-fail') {
			return (
				<p style= { { backgroundColor: 'rgba(255,0,0,.5)' } }>
					Uppladdning misslyckades
				</p>
			);
		} else if(this.state.uploadState == 'upload') {
			return (
				<p style= { { backgroundColor: 'rgba(0,168,120,.5)' } }>
					Laddar upp...
				</p>
			);
		}
	}


	renderActions() {
		if (this.state.successfullyUploaded) {
			return (
				<button
					onClick={() =>
						this.setState({ files: [], successfullyUploaded: false, filesProcessed: [],uploadState: '' })
					}
				>
					Clear
				</button>
			);
		} else {
			return (
				<button
					disabled={this.state.files.length < 0 || this.state.uploading}
					onClick={this.uploadFiles}
				>
					Ladda upp
				</button>
			);
		}
	}


	render() {
		const { user } = this.state;
		const { password } = this.state;
		const { loginError } = this.state;
		const { showLogin } = this.state;
		return (
			<div className="Upload" >
				<div className="Content">
					<UserPrompt
						show={showLogin}
						user={user}
						updateUser={(user) => this.setState({ user })}
						password={password}
						updatePassword={(password) => this.setState({ password })}
						error={loginError}
						onSubmit={ (e) => {this.handleSubmit(e)}}
					/>
					<div>
						<Dropzone 
							onFilesAdded={this.onFilesAdded}
							disabled={this.state.uploading || this.state.successfullyUploaded}
						/>
					</div>
					<div className="Files">
						{this.state.files.map(file => {
							return (
								<div key={file.name} className="Row">
									{this.renderRemoveButton(file)}
									<span className="Filename">{file.name}</span>
									{/*{this.renderProgress(file)}*/}
								</div>
							);
						})}
					</div>
				</div>
				<div className="Message">{this.renderMessage()}</div>
				<div className="Actions">{this.renderActions()}</div>
			</div>
		);
	}
}


export default withCookies(UploadFile);