//import Dotenv from "dotenv-webpack"
import React from 'react'
import { CookiesProvider } from 'react-cookie';
import UploadWrapper from './UploadWrapper';

const App = () => {
  return (
  	<CookiesProvider>
    <div className="content" >
      <UploadWrapper />
    </div>
    </CookiesProvider>
  )
}

export default App