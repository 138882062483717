import React, {Component} from 'react'; 
import UploadFile from './UploadFile';


class UploadWrapper extends Component{
  constructor(props){
      super(props);
  }

  render(){

    return (
      <div className="upload-wrapper">
        <div className="plane-rotater">
          <div className="plane">
            <svg className="plane-svg" viewBox="0 0 28 26">
              <path className="plane-svg__path" fill="#fff" d="M0,0 28,13 0,26 0,13 20,13 0,7z" />
            </svg>
          </div>
        </div>
        <UploadFile />
      </div>
    );
  }
} 

export default UploadWrapper;