import React, {Component} from 'react'; 


class UserPrompt extends Component{
	constructor(props){
		super(props);
	}


	render(){

		const { onSubmit, user, updateUser, password, updatePassword, error, show } = this.props;

		return (
			<div className="UserPrompt" style= { { top: ( show ? '0px':'-180px' ) } }>

				<form onSubmit={onSubmit}>
					<input
						className="NameInput"
						type="text"
						value={user}
						onChange={(e) => updateUser(e.target.value)}
					/>
					<input
						className="PasswordInput"
						type="password"
						value={password}
						onChange={(e) => updatePassword(e.target.value)}
					/>
					<input className="SubmitInput" type="submit" value="Logga in" />
					<label className="InputError" style= { { display: ( error ? 'block':'none' ) } } >
						Felaktigt användarnamn eller lösenord
					</label>
				</form>
			</div>
		);
	}
} 

export default UserPrompt;